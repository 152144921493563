<template>
  <div class="router-404">
  </div>
</template>

<script>

export default {
  name: 'Page404',
  computed: {
    message() {
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.router-404{
  background-image: url("~@/assets/404_images/404页面.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
}
</style>
